<template>
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">{{ type.id ? 'Edit' : 'New' }} type<br>
                <div class="mt-1">
                  <div class="form-check form-check-success form-switch d-flex align-items-center">
                    <input
                      id="publishContent"
                      v-model="type.active"
                      type="checkbox"
                      class="form-check-input"
                    >
                    <label
                      class="form-check-label"
                      for="publishContent"
                    ><small class="ms-1">{{ labels['active'] }} <em
                      id="publishDate"
                      class="ms-2 text-muted"
                    ><small>12/12/2022</small></em></small></label>
                  </div>
                </div>
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to areas & groups list"
              @click="$router.push({name: 'admin.managerial-types.index'})"
            ><i data-feather="chevron-left" /></a>
            <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Senior call settings"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div
          v-if="errors != ''"
          class="alert alert-danger mt-1 p-1"
        >
          {{ errors }}
        </div>
        <div class="row">
          <form
            id="edit-form"
            action=""
          >
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                  <h3 class="cart-title">
                    <a data-action="collapse">Content</a>
                  </h3>
                  <div class="heading-elements">
                    <ul class="list-inline mb-0">
                      <li>
                        <a data-action="collapse"><i data-feather="chevron-down" /></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="card-content collapse show">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-8">
                        <div class="mb-1 form-group required">
                          <label
                            for=""
                            class="form-label"
                          >{{ labels['content.name'] }}</label>
                          <input
                            v-model="type.name"
                            class="form-control"
                            type="text"
                          >
                        </div>
                      </div>
                      <div class="col-sm-2">
                        <div class="mb-1 form-group required">
                          <label
                            for=""
                            class="form-label"
                          >{{ labels['content.code'] }}</label>
                          <input
                            v-model="type.code"
                            class="form-control"
                            type="text"
                          >
                        </div>
                      </div>
                      <div class="col-sm-2">
                        <div class="mb-1 form-group required">
                          <label
                            for=""
                            class="form-label"
                          >{{ labels['content.order'] }}</label>
                          <input
                            v-model="type.order"
                            class="form-control"
                            type="number"
                          >
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="mb-1 form-group required">
                          <label
                            for=""
                            class="form-label"
                          >{{ labels['content.description'] }}</label>
                          <quill-editor v-model="type.description" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </form>

        </div>

        <div class="page-actions">
          <button
            v-if="!sending"
            id="type-success"
            class="btn btn-success"
            @click="save"
          >
            <i data-feather="save" />
            <span class="ms-25 align-middle">Save and Exit</span>
          </button>
          <button
            v-else
            class="btn btn-outline-success"
            type="button"
            disabled=""
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span class="ms-25 align-middle">Saving...</span>
          </button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default {
  components: {},
  data() {
    return {
      typeId: this.$route.params.id,
      sending: false,
      errors: '',
    }
  },
  computed: {
    ...mapGetters({
      type: 'managerialTypes/item',
      labels: 'sectionLabels/itemLabels',
    }),
  },
  async mounted() {
    this.$store.dispatch('sectionLabels/fetchLabels', 'masters.activities_managerial_type_of_activity')
    if (this.typeId) {
      await this.$store.dispatch('managerialTypes/fetchId', this.typeId)
    } else {
      await this.$store.dispatch('managerialTypes/cleanType')
    }
  },
  methods: {
    async save() {
      this.sending = true

      try {
        if (this.typeId) {
          await this.$store.dispatch('managerialTypes/update', { id: this.typeId, data: this.type })
        } else {
          await this.$store.dispatch('managerialTypes/create', this.type)
        }
        this.errors = ''
      } catch (e) {
        this.errors = e.response.data.message
      }

      if (this.errors === '' && this.type.id) {
        Vue.swal('The type has been saved correctly!', '', 'success').then(() => {
          this.$router.push({ name: 'admin.managerial-types.index' })
        })
      } else {
        // Vue.swal
      }

      this.sending = false
    },
  },

}
</script>
